<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>Bienvenue sur le Livre d'Or</h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-button v-show="!addMsg" @click="addMsg = !addMsg">
                    <b-icon-chat-right-quote-fill /> Laisser un nouvel avis
                </b-button>
            </b-col>
        </b-row>
        <b-row v-if="addMsg">
            <b-col cols="9" md="11">
                <h4>Laissez un nouvel avis</h4>
            </b-col>
            <b-col cols="3" md="1" class="text-right">
                <b-button pill @click="addMsg = !addMsg">
                    <b-icon-x />
                </b-button>
            </b-col>
            <b-col cols="12">
                <ValidationObserver ref="formObs">
                    <b-form id="form" @submit.prevent="onSubmit">
                        <b-form-group ref="fname" label="Prénom" label-for="input_fname">
                            <ValidationProvider name="fname" :rules="{ required: 'prénom' }" v-slot="{ errors, valid }">
                                <b-form-input ref="input_fname" placeholder="Prénom" v-model="fname" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group ref="city" label="Votre ville" label-for="input_city">
                            <ValidationProvider name="city" :rules="{ required: 'ville' }" v-slot="{ errors, valid }">
                                <b-form-input ref="input_city" placeholder="Votre Ville" v-model="city" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group ref="msg" label="Votre avis" label-for="input_msg">
                            <ValidationProvider name="msg" :rules="{ required: 'avis' }" v-slot="{ errors, valid }">
                                <b-textarea ref="input_msg" placeholder="Laissez votre avis ici" rows="10"
                                    v-model="msg" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-button variant="primary" class="form_btn" @click="showResetModal">
                            <b-icon-trash /> Effacer
                        </b-button>
                        <b-button type="submit" class="form_btn float-right">
                            Valider
                            <b-icon-check2-circle />
                        </b-button>
                    </b-form>
                </ValidationObserver>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="12">
                <h6>{{ this.messages.length }} avis</h6>
            </b-col>
        </b-row>
        <b-row v-for="(message, key) in this.displayed_messages" :key="key" class="mt-4">
            <b-col cols="12">
                <b-icon-person-lines-fill />
                <span style="font-weight:bold;"> {{ message.fname }}</span> de
                {{ message.city }} a écrit {{ message.date }} :
            </b-col>
            <b-col cols="12">
                {{ message.msg }}
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col cols="6" offset="3" class="d-md-none" >
                <b-pagination v-model="currentPage" :total-rows="getNbMessages" :per-page="perPage"
                    @change="onPageChanged" />
            </b-col>
            <b-col cols="6" offset="3" class="d-none d-lg-flex" >
                <b-pagination v-model="currentPage" :total-rows="getNbMessages" :per-page="perPage"
                    @change="onPageChanged" size="lg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapState } from 'vuex';

import {
    BIcon,
    BIconChatRightQuoteFill,
    BIconX,
    BIconTrash,
    BIconCheck2Circle,
    BIconPersonLinesFill,
    BPagination,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
    name: 'GoldenBook',
    data: function () {
        return {
            fname: '',
            city: '',
            msg: '',
            date: '',
            addMsg: false,
            messages: [],
            displayed_messages: [],
            mailobj: {
                from: this.$store.state.donotanswmail,
                to: this.$store.state.mailadd,
                object: 'Un avis a été laissé sur le site',
                content: '',
            },
            currentPage: 1,
            perPage: 10,
        };
    },
    created() {
        let axios = require('axios');

        let config = {
            method: 'get',
            url: this.protocol + this.domainname + '/messages',
            headers: {
                Accept: 'application/json',
            },
        };

        axios(config)
            .then((response) => {
                this.messages = response.data;
                this.displayed_messages = this.messages;
                this.onPageChanged(1);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        BIcon,
        BIconChatRightQuoteFill,
        BIconX,
        BIconTrash,
        BIconCheck2Circle,
        BIconPersonLinesFill,
        BPagination,
    },
    methods: {
        /**
         * Ask for confirmation. Then stringify form for sending to serverside.
         */
        onSubmit() {
            this.$refs.formObs.validate().then((success) => {
                if (success) {
                    this.showConfirmModal(
                        'Êtes-vous sûr(e) de laisser cet avis ?',
                        'primary',
                        this.makeAxiosRequestPostReview
                    );
                } else {
                    this.$bvModal.msgBoxOk(
                        "Le formulaire n'est pas renseigné correctement. Si besoin, referrez-vous aux indications sous les champs.",
                        {
                            title: 'Données du formulaire mal renseignées',
                            okVariant: 'primary',
                            centered: true,
                        }
                    );
                }
            });
        },
        /**
         * Make the axios request to leave a message.
         */
        makeAxiosRequestPostReview() {
            let data = JSON.stringify({
                fname: this.fname,
                city: this.city,
                date: this.getCurrentDate,
                msg: this.msg,
            });

            let axios = require('axios');

            let config = {
                method: 'post',
                url: this.protocol + this.domainname + '/messages',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(config)
                .then((response) => {
                    this.$bvModal
                        .msgBoxOk('Votre message a bien été pris en compte.', {
                            title: 'Confirmation de prise en compte',
                            okVariant: 'primary',
                            centered: true,
                        })
                        .then(function () {
                            location.reload();
                        });
                })
                .catch((error) => {
                    console.log(error);
                });

            this.makeAxiosRequestSendMail(this.fname, this.city,
                this.getCurrentDate, this.msg);
        },
        /**
         * Make the axios request to send mail alert when a review is posted.
         */
        makeAxiosRequestSendMail(name, city, date, message) {
            let axios = require('axios');

            const content = 'Léa,\n\n'
                + 'Un message a été laissé par ' + name + ' de ' + city + ' à '
                + date + ' :\n'
                + message + '\n\nBonne journée Léa !';

            let data = JSON.stringify({
                from: this.mailobj.from,
                to: this.mailobj.to,
                object: this.mailobj.object,
                content: content,
            });

            let config = {
                method: 'post',
                url: this.protocol + this.domainname + '/sendmail',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(config)
                .catch((error) => {
                    console.log(error);
                });
        },
        /**
         * Display modal confirmation. If confirmed, run passed method as
         * callback. Else, does nothing.
         */
        showConfirmModal(message, variant, callback) {
            this.$bvModal
                .msgBoxConfirm(message, {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: variant,
                    okTitle: 'Je confirme',
                    cancelTitle: 'Annuler',
                    centered: true,
                })
                .then((value) => {
                    if (value && callback != undefined) {
                        callback();
                    }
                });
        },
        /**
         * Show modal confirmation. If confirmed,
         */
        showResetModal() {
            this.showConfirmModal(
                'Êtes-vous sûr(e) de vouloir effacer tout le formulaire ?',
                'danger',
                this.onReset
            );
        },
        /**
         * Reset formulaire on HTML side. Then reset this.data.
         */
        onReset() {
            document.getElementById('form').reset();
            this.fname = '';
            this.city = '';
            this.date = '';
            this.msg = '';
        },
        /**
         * Format given time to two digits string.
         */
        formatTime(time) {
            return time.length == 1 ? '0' + time : time;
        },
        /**
         * Function called when messages page is changed.
         * @param page - Target page.
         */
        onPageChanged(page) {
            let first_index = (page - 1) * this.perPage;
            this.displayed_messages = this.messages.slice(first_index, first_index + this.perPage);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
    computed: {
        ...mapState(['domainname', 'protocol']),
        /**
         * Returns formated current date.
         * @returns text.
         */
        getCurrentDate() {
            const months = [
                'janvier',
                'février',
                'mars',
                'avril',
                'mai',
                'juin',
                'juillet',
                'aout',
                'septembre',
                'octibre',
                'novembre',
                'décembre',
            ];

            let today = new Date();

            return (
                'le ' +
                today.getDate() +
                ' ' +
                months[today.getMonth()] +
                ' ' +
                today.getFullYear() +
                ' à ' +
                this.formatTime(today.getHours()) +
                'h' +
                this.formatTime(today.getMinutes())
            );
        },
        /**
         * Returns the total number of messages.
         * @returns number.
         */
        getNbMessages() {
            return this.messages.length;
        }
    },
};
</script>

<style scoped>
h1 {
    line-height: 150%;
}
.form_btn {
    min-width: 10em;
}
</style>
